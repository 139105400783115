



import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import saveAs from "save-as";

@Component({ components: { Datepicker } })
export default class ProcessorReportComponent extends Vue {
  public fromDate:any = null;
  public toDate: any = null;
  public toDisabledDate:any = new Date(Date.now() + 8640000);
  public fromDisableDate:any = null;
  public dateValidation:any = false;

  public async openDatePicker(type) {
    this.$modal.show("datepickerPopup");
  }

  public closeDatePickerModel(){
    this.$modal.hide('datepickerPopup');
    this.dateValidation = false;
    this.fromDate = null;
    this.toDate = null;
  }

  public async generateCSVForConditionalApprovalTask(){
    try{
      this.dateValidation = false;
      let valid = await this.$validator.validateAll();
      if(!valid)
        return;
      let dateCheck = await this.checkDate();
      if(!dateCheck){
        this.dateValidation = true;
        return
      } 
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "common/generateCSVForConditionalApprovalTask",
        { 
          fromDate: this.fromDate,
          toDate: this.toDate
        },
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, "ProcessorsConditionalApprovalTask.csv");
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
    }catch(error){
      console.log(error);
    }
    this.closeDatePickerModel();
    this.$store.state.wemloLoader = false;
  }

  private checkDate() {
    let fromTime = this.fromDate.getTime();
    let toTime = this.toDate.getTime();
    return fromTime == toTime || toTime > fromTime ? true : false;
  }

  public async generateCSVForActiveLoanswithProcessor() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "common/generateCSVForActiveLoanswithProcessor",
        {},
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, "ActiveLoans.csv");
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }
}
