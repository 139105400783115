



import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import saveAs from "save-as";

@Component({ components: { Datepicker } })
export default class BrokerReportComponent extends Vue {
  public dates: any = {};
  public exportType:any = null;
  public async getAllBrokerList() {
    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.post(
        BASE_API_URL + "common/getAllBrokerList",
        {},
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, `agreement.zip`);
        this.$store.state.wemloLoader = false;
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
        this.$store.state.wemloLoader = false;
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public async getAllLoanSubmittedByBroker() {
    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.post(
        BASE_API_URL + "common/getAllLoanSubmittedByBroker",
        { date: this.dates},
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, `Broker-List.csv`);
        this.$store.state.wemloLoader = false;
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
        this.$store.state.wemloLoader = false;
      }
      this.resetDatepickerDates();
      this.$modal.hide("datepickerPopup");
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public async resetDatepickerDates(){
    let date = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    this.dates.to = new Date(year, month, date);
    var newdate = new Date(this.dates.to).setDate(this.dates.to.getDate() - 15);
    this.dates.from = new Date(newdate);
  }

  public async exportLenderData() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "common/exportLenderData",
        {},
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, "Broker-Lender.csv");
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public async generateCSVForLoanNotSubmitted() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "common/generateCSVForLoanNotSubmitted",
        { date: this.dates},
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, "BrokerLoanNotSubmitted.csv");
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
      this.resetDatepickerDates();
      this.$modal.hide("datepickerPopup");
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public async openDatePicker(type) {
    try {
      this.$modal.show("datepickerPopup");
      this.exportType = type;
    } catch (error) {
      console.log(error);
    }
  }

  public async searchByDate() {
    try {
      let clearReportStartDate = this.dates.from.getDate();
      let clearReportStartMonth = this.dates.from.getMonth();
      let clearReportStartYear = this.dates.from.getFullYear();

      this.dates.from = new Date(
        clearReportStartYear,
        clearReportStartMonth,
        clearReportStartDate
      );
      let from = this.dates.from;

      let clearReportEndDate = this.dates.to.getDate();
      let clearReportEndMonth = this.dates.to.getMonth();
      let clearReportEndYear = this.dates.to.getFullYear();

      this.dates.to = new Date(
        clearReportEndYear,
        clearReportEndMonth,
        clearReportEndDate
      );
      let to = this.dates.to;
    } catch (error) {
      console.log(error);
    }
  }

  created() {
    // this.dates.from = moment();
    // this.dates.to = moment().subtract(10, "d");
    let date = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    this.dates.to = new Date(year, month, date);
    var newdate = new Date(this.dates.to).setDate(this.dates.to.getDate() - 15);
    this.dates.from = new Date(newdate);
  }
}
